/* eslint-disable no-trailing-spaces */
/* eslint-disable camelcase */
/* eslint-disable max-len */
import {
    DTO, DTORef, DTOMetaData, DTOMethods, DTOClassMethods,
    BoundEndpointAPI, Attachment, EventAttachment
} from '../dto';

import { EventType } from './eventtype';

export interface CoverageObjective_v1 extends DTO, DTOMethods<CoverageObjective_v1> {
    eventType: EventType;
    startDate: Date;
    endDate: Date;
    status: string;
    priority: number;
    rank: number;
    createdDate: Date;
    completedDate: Date;
    rag: string;
    progress: number;
    duration: number;
    durationType: string;
    durationUnit: string;
    isReadOnly: boolean;
    children: DTORef[];
    files: EventAttachment[];
    dependencies: Attachment[];
    responses: Attachment[];
    resolvedUsers: Attachment[];
    claimResults: string;
    footprint: DTORef;
}
export const CoverageObjective_v1: DTOMetaData & DTOClassMethods<CoverageObjective_v1> = {
    _label:'Coverage Objectives',
    _dto: 'CoverageObjective_v1',
    _type: 'coverage-objective',
    _colors: { primaryThemeColor: "#6d9b44", secondaryThemeColor: "#558a1f", tertiaryThemeColor: "#558a1f" },
    _keysets: ["id"], // TODO: Generate from DTO Schema
    _properties: [
        { label: 'Event type', prop: 'eventType', hint: 'Event type', type: 'enum', fixed: false, source: undefined, values: ["OBJECTIVE"], default: EventType.OBJECTIVE },
        { label: 'Start date', prop: 'startDate', hint: 'Date and time', type: 'Date', fixed: true, source: undefined, values: undefined, default: new Date() },
        { label: 'End date', prop: 'endDate', hint: 'Date and time', type: 'Date', fixed: true, source: undefined, values: undefined, default: new Date() },
        { label: 'Status', prop: 'status', hint: 'Text (max 64k chars)', type: 'string', fixed: true, source: undefined, values: undefined, default: "New" },
        { label: 'Priority', prop: 'priority', hint: 'Integer value (-2147483648..2147483647)', type: 'number', fixed: true, source: undefined, values: undefined, default: 0 },
        { label: 'Rank', prop: 'rank', hint: 'Integer value (-2147483648..2147483647)', type: 'number', fixed: true, source: undefined, values: undefined, default: 0 },
        { label: 'Created date', prop: 'createdDate', hint: 'Date and time', type: 'Date', fixed: true, source: undefined, values: undefined, default: new Date() },
        { label: 'Completed date', prop: 'completedDate', hint: 'Date and time', type: 'Date', fixed: true, source: undefined, values: undefined, default: new Date() },
        { label: 'Rag', prop: 'rag', hint: 'Text (max 64k chars)', type: 'string', fixed: true, source: undefined, values: undefined, default: "" },
        { label: 'Progress', prop: 'progress', hint: 'Integer value (-2147483648..2147483647)', type: 'number', fixed: true, source: undefined, values: undefined, default: 0 },
        { label: 'Duration', prop: 'duration', hint: 'Decimal value (7 decimals)', type: 'number', fixed: true, source: undefined, values: undefined, default: 0 },
        { label: 'Duration type', prop: 'durationType', hint: 'Duration type', type: 'enum', fixed: false, source: undefined, values: ["FIXEDDURATION", "FIXEDEND"], default: "FIXEDEND" },
        { label: 'Duration unit', prop: 'durationUnit', hint: 'Duration unit', type: 'enum', fixed: false, source: undefined, values: ["HOUR", "DAY", "WEEK", "MONTH", "QUARTER", "YEAR"], default: "DAY" },        { label: 'Is read only', prop: 'isReadOnly', hint: 'Yes/No', type: 'boolean', fixed: true, source: undefined, values: undefined, default: false },
        { label: 'Claim results', prop: 'claimResults', hint: 'Text (max 64k chars)', type: 'string', fixed: true, source: undefined, values: undefined, default: "" },
        { label: 'Footprint', prop: 'footprint', hint: 'Footprint', type: 'dto', fixed: true, source: '/', values: undefined, default: undefined },
    ],
    _related: [
        { prop: 'children', type: 'task', class: 'Task_v1', label: 'Tasks' }
    ],
    _childrenAt: 'children',
    _endpoint: '/api/pmo/v1.0/coverage-objective/v1.0',
    _depth: 2,
    endpointAPI: new BoundEndpointAPI<CoverageObjective_v1>('CoverageObjective_v1', DTO),
    _docLink: '/wiki/elevate/Assets/coverage-objective/',
    _icon: './assets/dtos/coverage-objective.svg',

    from: (obj: any): CoverageObjective_v1 => {
        return Object.setPrototypeOf(obj, CoverageObjective_v1._prototype);
    },
    select: (p: any = ''): any => {
        return CoverageObjective_v1.endpointAPI.get(p).then(res =>
            !res ? undefined
            : Array.isArray(res) ? res.map(a => CoverageObjective_v1.from(a) as CoverageObjective_v1)
            : CoverageObjective_v1.from(res)
    )}
}
